
/**
 * Created by wangtong on 2021/12/11
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import SplatPcHomeBackground from "@/components/home/SplatPcHomeBackground.vue";
import SplatPcAppDownloader from "@/components/app_downloader/SplatPcAppDownloader.vue";
import SplatFooter from "@/components/common/SplatFooter.vue";
import {OfficialWebsiteQuery} from "@/models";

@Component({
    components: {
        SplatPcAppDownloader, 
        SplatPcHomeBackground,
        SplatFooter
    }
})
export default class PcHome extends Vue {
    @Prop() query?: OfficialWebsiteQuery
}
